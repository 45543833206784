import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

import { Finance } from "../components/icons/Finance"
import { Card } from "../components/icons/Card"

import Furnished from "../icons/furnished.svg"
import Prezentace from "../icons/appRealEstate.svg"
import Elevator from "../icons/elevator.svg"
import Smlouva from "../icons/deedOfSale.svg"
import Handshake from "../icons/handshake.svg"
import OkHouse from "../icons/satisfactionGuaranteed.svg"

const JakPracujemePage = () => {
  const data = useStaticQuery(graphql`
    query JakPracujeme {
      scomos {
        p1: pageItem(id: "/api/page_items/379") {
          content
        }
        p2: pageItem(id: "/api/page_items/380") {
          content
        }
        p3: pageItem(id: "/api/page_items/381") {
          content
        }
        p4: pageItem(id: "/api/page_items/382") {
          content
        }
        p5: pageItem(id: "/api/page_items/383") {
          content
        }
        p6: pageItem(id: "/api/page_items/384") {
          content
        }
        p7: pageItem(id: "/api/page_items/385") {
          content
        }
        n1: pageItem(id: "/api/page_items/387") {
          content
        }
        n2: pageItem(id: "/api/page_items/388") {
          content
        }
        n3: pageItem(id: "/api/page_items/389") {
          content
        }
        n4: pageItem(id: "/api/page_items/390") {
          content
        }
        n5: pageItem(id: "/api/page_items/391") {
          content
        }
        n6: pageItem(id: "/api/page_items/392") {
          content
        }
      }
    }
  `)
  const boxes = data?.scomos
  const prodej = [
    { content: boxes.p1.content, icon: <Card /> },
    { content: boxes.p2.content, icon: <Finance /> },
    { content: boxes.p3.content, icon: <Furnished /> },
    { content: boxes.p4.content, icon: <Prezentace /> },
    { content: boxes.p5.content, icon: <Elevator /> },
    { content: boxes.p6.content, icon: <Smlouva /> },
    { content: boxes.p7.content, icon: <Handshake /> },
  ]
  const najem = [
    { content: boxes.n1.content, icon: <Card /> },
    { content: boxes.n2.content, icon: <Finance /> },
    { content: boxes.n3.content, icon: <OkHouse /> },
    { content: boxes.n4.content, icon: <Prezentace /> },
    { content: boxes.n5.content, icon: <Smlouva /> },
    { content: boxes.n6.content, icon: <Handshake /> },
  ]

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jak pracujeme | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative h-96">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <StaticImage
            src="../images/business.jpg"
            className="h-full w-full absolute object-cover border border-gray-200 "
            alt="Malgen"
          />
          <div className="absolute bottom-0  sm:overflow-hidden w-full border-t border-gray-400">
            <div className="absolute inset-0 bottom-0">
              <div className="absolute inset-0 bg-gray-300 mix-blend-multiply backdrop-filter backdrop-blur-sm" />
            </div>
            <div className="relative px-4 max-w-7xl mx-auto">
              <h1 className="text-center  text-2xl lg:text-3xl grid py-6 px-3 ">
                <span className=" text-white">
                  I složité záležitosti vyřešíme snadno
                </span>
                <span className=" text-white text-2xl"></span>
              </h1>
            </div>
          </div>
        </div>
        <div>
          <div className=" py-12 max-w-6xl mx-auto  relative px-8 ">
            <div className="relative   ">
              <div className="relative">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  Jak pracujeme při prodeji nemovitosti
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Nasloucháme Vám a ptáme se, abychom skutečně pochopili Vaše
                  cíle a mohli tak doporučit nejlepší strategii pro nalezení
                  kupce. Je pro Vás důležité prodat za nejvyšší možnou cenu,
                  nebo dáváte přednost úspoře času? Přejete si využít všechny
                  dostupné možnosti inzerce, nebo je pro Vás důležité, aby
                  prodej proběhl diskrétně?
                </p>

                <dl className="mt-10 space-y-10">
                  {prodej.map((item, i) => (
                    <div key={i} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 p-2 text-white">
                          {item.icon && <>{item.icon}</>}
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {item?.name}
                        </p>
                      </dt>
                      <dd
                        className="mt-2 ml-16 text-base text-gray-500"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          <div className=" py-12 max-w-6xl mx-auto  relative px-8 ">
            <div className="relative   ">
              <div className="relative">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  Jak pracujeme při pronájmu nemovitosti
                </h3>

                <dl className="mt-10 space-y-10">
                  {najem.map((item, i) => (
                    <div key={i} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 p-2 text-white">
                          {item.icon && <>{item.icon}</>}
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {item?.name}
                        </p>
                      </dt>
                      <dd
                        className="mt-2 ml-16 text-base text-gray-500"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
          <div className="relative max-w-xl mx-auto my-5">
            <div
              className="absolute px-5 inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-gray-500">
                <svg
                  version="1.1"
                  id="Layer_1"
                  fill="currentColor"
                  className="w-10"
                  viewBox="0 0 114.563 60.68"
                >
                  <path
                    d="M30.665,58.295c7.085,0.098,13.808-2.571,18.891-7.516c1.815-1.766,3.334-3.776,4.565-5.921
                            c0.308,0.154,0.626,0.278,0.987,0.283l16.678,0.462c0.459,0.006,0.901-0.128,1.296-0.372l4.423-2.841l6.384,2.95
                            c0.707,0.321,1.518,0.262,2.164-0.13l6.068-3.828l7.859,4.142c0.865,0.442,1.929,0.297,2.628-0.384l8.207-7.983
                            c0.903-0.878,0.913-2.31,0.044-3.203l-7.983-8.207c-0.413-0.426-0.99-0.674-1.581-0.693l-44.859-0.611
                            C53.277,13.502,43.371,5.368,31.413,5.193c-7.084-0.099-13.797,2.581-18.879,7.524c-5.083,4.944-7.947,11.58-8.035,18.654
                            C4.294,46.021,16.028,58.104,30.665,58.295z M56.051,40.631c0.948-2.699,1.498-5.563,1.54-8.525
                            c0.004-1.071-0.171-2.084-0.277-3.126l42.968,0.595l5.753,5.916l-5.406,5.259l-7.552-3.987c-0.717-0.371-1.587-0.333-2.274,0.098
                            l-6.118,3.858l-6.404-2.971c-0.706-0.319-1.517-0.261-2.174,0.161l-4.888,3.135L56.051,40.631z M9.021,31.456
                            c0.082-5.884,2.459-11.396,6.67-15.492s9.776-6.31,15.66-6.228c12.138,0.158,21.877,10.171,21.707,22.316
                            c-0.081,5.883-2.449,11.386-6.66,15.481c-4.21,4.096-9.787,6.32-15.67,6.238C18.592,53.595,8.853,43.583,9.021,31.456z"
                  />
                  <path
                    d="M15.286,31.542c-0.081,5.704,4.509,10.422,10.213,10.5c2.771,0.028,5.389-1.016,7.367-2.94
                            c1.979-1.924,3.094-4.511,3.133-7.272c0.089-5.713-4.499-10.431-10.213-10.5C20.246,21.236,15.357,25.993,15.286,31.542z
                            M19.817,31.595c0.022-1.562,0.637-3.019,1.754-4.105c1.125-1.095,2.6-1.67,4.151-1.639c3.203,0.044,5.788,2.702,5.752,5.915
                        c-0.053,3.112-2.802,5.786-5.914,5.734C22.349,37.465,19.763,34.807,19.817,31.595z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                  Kávu platíme my
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Pomáháme firmám i jednotlivcům
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                  zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                  zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
                </p>
              </div>

              <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-xl  font-medium text-gray-900">
                    800 991 100
                  </p>
                </div>
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <MailIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <a
                    href="mailto:office@malgen.cz"
                    className="ml-3 text-xl  font-medium text-gray-900"
                  >
                    office@malgen.cz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default JakPracujemePage
